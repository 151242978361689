/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Row, Col } from 'react-bootstrap';
import api from '../../../services/API';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import moment from 'moment-timezone';
import sortFunc from '../../../utils/ordernarColunaInt';
import { CSVLink } from 'react-csv';

const { SearchBar } = Search;

const ExportCSV = (props) => {
  return (
    <div>
      <CSVLink
        data={props.data}
        separator={';'}
        filename={`ProdutosVendidos${moment().format('YYYYMMDDHHmmss')}.csv`}
        headers={[
          {
            key: 'revenda',
            label: 'Loja',
          },
          {
            key: 'mes',
            label: 'Mês',
          },
          {
            key: 'codProduto',
            label: 'Cód. MAM',
          },
          {
            key: 'produto',
            label: 'Produto Vendido',
          },
          {
            key: 'quantidade',
            label: 'Qtd.',
          },
          {
            key: 'valorUnitarioMedio',
            label: 'Valor Unitário Médio',
          },
          {
            key: 'valorTotal',
            label: 'Valor Total',
          },
          {
            key: 'totalPontuado',
            label: 'Total Pontuado',
          },
        ]}
      >
        <button className="btn-primario-slim ml-2">
          <i className="fa fa-file-download px-2"></i>
        </button>
      </CSVLink>
    </div>
  );
};

const meses = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export default function ProdutosVendidos() {
  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      hidden: true,
      csvExport: false,
      formatter: (celula, valor) => {
        return `${valor.revenda}${valor.codProduto}`;
      },
    },
    {
      dataField: 'revenda',
      text: 'Loja',
      sort: true,
    },
    {
      dataField: 'mes',
      text: 'Mês',
      sort: true,
      formatter: (celula, valor) => {
        return meses[valor.mes - 1];
      },
    },
    {
      dataField: 'codProduto',
      text: 'Cód. MAM',
      sort: true,
      sortFunc: sortFunc,
    },
    {
      dataField: 'produto',
      text: 'Produto Vendido',
      sort: true,
    },
    {
      dataField: 'quantidade',
      text: 'Qtd.',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.quantidade).toLocaleString('pt-BR');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'valorUnitarioMedio',
      text: 'Valor Unitário Médio',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.valorUnitarioMedio)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'valorTotal',
      text: 'Valor Total',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.valorTotal)
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .toString()
          .replace(/\u00a0/g, ' ');
      },
      sortFunc: sortFunc,
    },
    {
      dataField: 'totalPontuado',
      text: 'Total Pontuado',
      sort: true,
      formatter: (celula, valor) => {
        return Number(valor.totalPontuado).toLocaleString('pt-BR');
      },
      sortFunc: sortFunc,
    },
  ];

  useEffect(() => {
    buscarRevendas();
    buscarPrimeiraData();
    buscarProdutosVendidos();
  }, []);

  const buscarRevendas = async () => {
    const resultado = await api.get('/api/revendas?ativo=1');
    if (resultado && resultado.data.revendas) {
      setRevendasSelect(resultado.data.revendas);
    }
  };

  const buscarProdutosVendidos = async () => {
    setBuscando(true);
    const resultado = await api.get(
      `/api/produtos_vendidos?revenda=${revendaSelecionada}&ano=${anoSelecionado}`
    );
    if (resultado && resultado.data.produtosVendidos) {
      setData(resultado.data.produtosVendidos);
    }
    setBuscando(false);
  };

  const [data, setData] = useState([]);
  const [anoSelecionado, setAnoSelecionado] = useState(
    Number(new Date().getFullYear())
  );
  const [anos, setAnos] = useState([]);
  const [revendaSelecionada, setRevendaSelecionada] = useState('');
  const [revendasSelect, setRevendasSelect] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [dataFormated, setDataFormated] = useState([]);

  useEffect(() => {
    if (data) {
      setDataFormated(
        data.map((d) => ({
          revenda: d.revenda,
          mes: d.mes.toString().length === 1 ? `0${d.mes}` : d.mes,
          codProduto: d.codProduto,
          produto: d.produto,
          quantidade: Number(d.quantidade).toLocaleString('pt-BR'),
          valorUnitarioMedio: Number(d.valorUnitarioMedio)
            .toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
            .toString()
            .replace(/\u00a0/g, ' '),
          valorTotal: Number(d.valorTotal)
            .toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })
            .toString()
            .replace(/\u00a0/g, ' '),
          totalPontuado: Number(d.totalPontuado).toLocaleString('pt-BR'),
        }))
      );
    }
  }, [data]);

  const buscarPrimeiraData = async () => {
    const resultado = await api.get('/api/historico/primeiraData');
    if (resultado && resultado.data.primeiraData) {
      const anos = [];
      for (
        let i = Number(moment(resultado.data.primeiraData).format('YYYY'));
        i <= Number(moment().format('YYYY'));
        i += 1
      ) {
        anos.push(Number(i));
      }
      setAnos(anos);
    }
  };

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Produtos Vendidos</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="revendaSelecionada">Selecione uma Revenda</label>
          <select
            className="input-select mb-3"
            name="revendaSelecionada"
            id="revendaSelecionada"
            onChange={(e) => setRevendaSelecionada(e.target.value)}
            defaultValue=""
            placeholder=""
          >
            <option value="">Todas</option>
            {revendasSelect.map((revenda) => (
              <option value={revenda.cnpj} key={revenda.cnpj}>
                {revenda.nomeFantasia}
              </option>
            ))}
          </select>
        </Col>
        <Col xs={12} sm={3} md={3} lg={2} className="d-flex flex-column">
          <label htmlFor="anoSelecionado">Ano</label>
          <select
            className="input-select mb-3"
            name="anoSelecionado"
            id="anoSelecionado"
            onChange={(e) => setAnoSelecionado(e.target.value)}
            value={anoSelecionado}
          >
            {anos.map((ano) => (
              <option value={ano} key={ano}>
                {ano}
              </option>
            ))}
          </select>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button
            className="btn-busca"
            type="submit"
            onClick={buscarProdutosVendidos}
          >
            {buscando ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search"></i>
            )}
          </button>
        </Col>
      </Row>
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          search
          exportCSV
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <ExportCSV {...props.csvProps} data={dataFormated} />
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme"
                />
              </div>
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </Container>
  );
}
