import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import api from '../../../../services/API';
export default function ModalMassRescueAlteration(props) {
  const [status, setStatus] = useState('');
  const [idsToSelection, setIdsToSelection] = useState('');
  const massRescueUpdate = async (data) => {
    const result = await api.patch('/api/resgates/bulk', data);
    if (result) props.onHide({ ids: idsToSelection, status });
  };
  return (
    <Modal
      show={props.open}
      onHide={() => {
        setStatus('');
        setIdsToSelection('');
        props.onHide();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Selecionar ID's</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="form" style={{ width: '100%', maxWidth: 'none' }}>
          <select
            id="status"
            name="status"
            placeholder="Status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            required
          >
            <option disabled value="">
              Selecione o novo status
            </option>
            <option value="0">Não entregue</option>
            <option value="1">Entregue</option>
            <option value="2">Em trânsito</option>
            {/* <option value="3">Cancelado</option> */}
          </select>
        </form>
        <p>Forneça os ID's separados por virgulas.</p>
        <p>
          <strong>Exemplo: 1,2,3,4,5</strong>
        </p>
        <InputGroup>
          <Form.Control
            value={idsToSelection}
            onChange={(e) => setIdsToSelection(e.target.value)}
          />
        </InputGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setStatus('');
            setIdsToSelection('');
            props.onHide();
          }}
        >
          Fechar
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            const ids = [];
            if (status === undefined || status === null || status === '') {
              toast.error('Status é obrigatório.', {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 10000,
              });
              return;
            }
            if (!idsToSelection) {
              toast.error('Informe pelo menos um id.', {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 10000,
              });
              return;
            }
            for (let id of idsToSelection.replaceAll(' ', '').split(',')) {
              if (isNaN(id)) {
                toast.error(`Id inválido encontrado: ${id}`, {
                  position: toast.POSITION.BOTTOM_CENTER,
                  autoClose: 10000,
                });
                return;
              } else ids.push(Number(id));
            }
            massRescueUpdate({ status: Number(status), ids });
            setStatus('');
            setIdsToSelection('');
          }}
        >
          Selecionar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
