import React, { useState } from 'react';
import { Form, Input } from '@rocketseat/unform';
import { Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import api from '../../../services/API';
import Icone from '../Icone';

export const ConfimarEndereco = ({
  state,
  premio,
  qtde,
  resgatar,
  setConfirmar,
  updateUser,
}) => {
  const [open, setOpen] = useState('ENDERECO');
  const { enderecoEntrega } = state;
  const [completo, setCompleto] = useState(
    enderecoEntrega?.entregaCep &&
      enderecoEntrega?.entregaNome &&
      enderecoEntrega?.entregaNumero
      ? true
      : false
  );
  const buscarCep = async (element) => {
    let input = element.target;
    let cep = input.value.replace(/[^\d]/g, '');
    if (!cep) return;
    const resultado = await axios.get(`https://viacep.com.br/ws/${cep}/json`);
    if (!resultado || resultado.data.erro) {
      toast.error('CEP inválido', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      setData({ entregaCep: '' });
    } else {
      const { data } = resultado;
      setData({
        entregaRua: data.logradouro,
        entregaCep: data.cep,
        entregaCidade: data.localidade,
        entregaBairro: data.bairro,
        entregaEstado: data.uf,
        entregaComplemento: data.entregaComplemento,
      });
    }
  };

  const [data, setData] = useState({
    entregaRua: enderecoEntrega?.entregaRua,
    entregaCep: enderecoEntrega?.entregaCep,
    entregaCidade: enderecoEntrega?.entregaCidade,
    entregaBairro: enderecoEntrega?.entregaBairro,
    entregaEstado: enderecoEntrega?.entregaEstado,
    entregaNumero: enderecoEntrega?.entregaNumero,
    entregaComplemento: enderecoEntrega?.entregaComplemento,
    entregaNome: enderecoEntrega?.entregaNome,
  });

  const atualizarEndereco = () => {
    try {
      updateUser(data, completo);
    } catch (error) {
      console.log('Erro ao tentar atualizar o endereço');
    }
  };

  return (
    <>
      {open === 'ENDERECO' && !completo && (
        <Form
          className="form"
          onSubmit={(e) => {
            atualizarEndereco(e);
            setData(e);
            setOpen('CONFIMAR');
          }}
        >
          <Row>
            <span className="p-2">
              <strong>Preencha os dados do endereço de entrega</strong>
            </span>
          </Row>
          <Row className="d-flex align-items-center">
            <Col xs={12} md={6} className="p-1">
              <label htmlFor="entregaCep">CEP</label>
              <Input
                id="entregaCep"
                name="entregaCep"
                type="text"
                required
                onBlur={(e) => {
                  buscarCep(e);
                }}
                onChange={(e) => {
                  setData({ ...data, entregaCep: e.target.value });
                }}
                value={data.entregaCep}
                placeholder="Digite o CEP do endereço de entrega"
                className="w-100"
              ></Input>
            </Col>
            <Col xs={12} md={6} className="p-1">
              <label htmlFor="entrega">Entrega Para</label>
              <Input
                id="entregaNome"
                name="entregaNome"
                required
                type="text"
                className="w-100"
                placeholder="Quem receberá o premio?"
                onChange={(e) => {
                  setData({ ...data, entregaNome: e.target.value });
                }}
                value={data.entregaNome}
              ></Input>
            </Col>
            <Col xs={12} md={10} className="p-1">
              <label htmlFor="entregaRua">Endereco</label>
              <Input
                id="entregaRua"
                name="entregaRua"
                type="text"
                required
                className="w-100"
                placeholder="Ex: Rua, Av, Travessa"
                onChange={(e) => {
                  setData({ ...data, entregaRua: e.target.value });
                }}
                value={data.entregaRua}
              ></Input>
            </Col>
            <Col xs={12} md={2} className="p-1">
              <label htmlFor="entregaNumero">Número</label>
              <Input
                id="entregaNumero"
                name="entregaNumero"
                type="text"
                required
                className="w-100"
                onChange={(e) => {
                  setData({ ...data, entregaNumero: e.target.value });
                }}
                value={data.entregaNumero}
              ></Input>
            </Col>
            <Col xs={12} md={6} className="p-1">
              <label htmlFor="entregandBairroirro">Complemento</label>
              <Input
                id="entregaComplemento"
                name="entregaComplemento"
                type="text"
                required
                className="w-100"
                onChange={(e) => {
                  setData({ ...data, entregaComplemento: e.target.value });
                }}
                value={data.entregaComplemento}
              ></Input>
            </Col>
            <Col xs={12} md={6} className="p-1">
              <label htmlFor="entregandBairroirro">Bairro</label>
              <Input
                id="entregaBairro"
                name="entregaBairro"
                type="text"
                required
                className="w-100"
                onChange={(e) => {
                  setData({ ...data, entregaBairro: e.target.value });
                }}
                value={data.entregaBairro}
              ></Input>
            </Col>
            <Col xs={12} md={6} className="p-1">
              <label htmlFor="entregaCidade">Cidade</label>
              <Input
                id="entregaCidade"
                name="entregaCidade"
                type="text"
                required
                className="w-100"
                onChange={(e) => {
                  setData({ ...data, entregaCidade: e.target.value });
                }}
                value={data.entregaCidade}
              ></Input>
            </Col>
            <Col xs={12} md={2} className="p-1">
              <label htmlFor="entregaEstado">UF</label>
              <Input
                id="entregaEstado"
                name="entregaEstado"
                type="text"
                required
                className="w-100"
                onChange={(e) => {
                  setData({ ...data, entregaEstado: e.target.value });
                }}
                value={data.entregaEstado}
              ></Input>
            </Col>
          </Row>
          <Row>
            <button
              className="btn-resgatar"
              type="submit"
              style={{ whiteSpace: 'nowrap' }}
            >
              <i className="fas fa-shopping-cart"></i> CONFIRMAR
            </button>
          </Row>
        </Form>
      )}
      {(open === 'CONFIMAR' || completo) && (
        <>
          <div>
            <p className="p-2 text-center">
              Tem certeza que deseja resgatar{' '}
              <span className="red">
                <b>{`${qtde} x ${premio.nome}`}</b>
              </span>{' '}
              por{' '}
              <span className="red">
                {`${Number(premio.pontos * qtde).toFixed(0)} pontos`}
              </span>
            </p>
          </div>
          {premio.tipo !== 'recarga' && premio.tipo !== 'credito' && (
            <div className="mr-4 ml-4 mb-4 mt-1 text-center">
              <span style={{ fontSize: '18px' }} className="p-2">
                Entregar para <strong>{data.entregaNome}</strong>
                <br />
              </span>
              <span className="p-2">
                {data.entregaRua} , {data.entregaNumero} ,{' '}
                {data.entregaComplemento} - {data.entregaBairro}
                <br />
              </span>
              <span className="p-2">
                {data.entregaCidade} / {data.entregaEstado} - CEP -{' '}
                {data.entregaCep}
              </span>
              <div style={{ width: '100%' }}>
                <button
                  className="btn btn-light m-2"
                  style={{ backgroundColor: '#dddddd' }}
                  onClick={() => {
                    setOpen('ENDERECO');
                    setCompleto(false);
                  }}
                >
                  ALTERAR ENDEREÇO
                </button>
              </div>
            </div>
          )}
          <div className="text-center">
            <button
              className="btn btn-success m-2"
              onClick={() => resgatar(data)}
            >
              <i className="fas fa-check"></i> CONFIMAR
            </button>
            <button
              className="btn btn-danger m-2"
              onClick={() => setConfirmar(false)}
            >
              <i className="fas fa-times"></i> NÃO
            </button>
          </div>
        </>
      )}
    </>
  );
};
