import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { atualizarSaldo } from '../../../store/modules/parceiro/actions';
import { Container } from 'react-bootstrap';

export default function Regulamento() {
  const dispatch = useDispatch();
  const parceiro = useSelector((state) => state.parceiro);

  useEffect(() => {
    dispatch(atualizarSaldo(parceiro.cpf));
  }, []);

  return (
    <Container className="mt-5 mb-5">
      <div>
        <h5>REGULAMENTO MAM E SMART</h5>
        <hr className="hr-loja mt-0" />
      </div>
      <div className="text-justify">
        <div>
          <p>
            O “MAM” é um Programa de Incentivo para vendedores cadastrados, no
            qual cada produto da marca MAM vendido tem um valor de pontos
            específico, que poderá ser alterado a qualquer momento.
          </p>
          <p>
            O vendedor da loja cadastrado acumulará pontos e os trocará por
            prêmios, de acordo com regras de participação e pontuação descritas
            neste documento.
          </p>
          <p>
            O presente Programa “MAM Points” é desenvolvido e realizado pela
            Smart Tecnologia em Vendas S/A, inscrita no CNPJ sob nº
            20.893.968/0001-00, denominada “Smart”, e promovido pela BEBE SAUDE
            LTDA, inscrita no CNPJ sob o nº 02.729.687/0001-26,
            denominada “MAM”.
          </p>
          <p>
            Todas as regras de pontuação, resgates e validade, poderão ser
            atualizadas sem prévio aviso, sendo incluídas neste documento e
            comunicado aos participantes de forma clara. A pré-seleção das
            revendas participantes é de responsabilidade da MAM.
          </p>
          <p>
            O participante, ao se cadastrar no Programa, aceita e concorda com
            as regras abaixo.
          </p>
        </div>
        <br />
        <div>
          <ol>
            <li>
              <span>REGULAMENTO</span>
              <ol>
                <li>
                  <span>
                    O presente Programa será realizado conforme regras descritas
                    neste Regulamento.
                  </span>
                </li>
                <li>
                  <span>
                    Este Programa é direcionado exclusivamente aos membros do
                    Programa MAM Points cadastrados nos estabelecimentos
                    credenciados, sendo todos pessoas físicas e maiores de 18
                    (dezoito) anos, que já tenham o hábito de se relacionar com
                    a empresa promotora.
                  </span>
                </li>
                <li>
                  <span>
                    O Programa MAM Points é destinado exclusivamente aos
                    vendedores das lojas participantes, não sendo permitida a
                    participação de proprietários.
                  </span>
                </li>
                <li>
                  <span>
                    Os dados fornecidos pelas lojas ou participantes que não
                    forem reais, automaticamente serão descadastrados e os
                    pontos expirados, podendo estender o descadastramento e
                    expiração dos pontos de todos os participantes da loja.
                  </span>
                </li>
                <li>
                  <span>
                    O cadastro do membro do Programa MAM Points deverá ser
                    efetuado através da Equipe de apoio ao membro do programa,
                    através do Formulário de Cadastro, onde a senha inicial será
                    1234, devendo ser alterada através do menu MINHA CONTA.
                  </span>
                </li>
                <li>
                  <span>
                    Nunca forneça a senha de acesso ao site a terceiros, a senha
                    é de total responsabilidade do vendedor da loja cadastrado.
                    Acesse a área Minha Conta no painel ou{' '}
                    <a target="_blank" href="/perfil">
                      nesse link
                    </a>{' '}
                    e altere sua senha inicial. Em caso de dúvidas ou extravios,
                    entre em contato com a Equipe MAM pelo telefone (11)
                    5198-9226 ou pelo email: contato@mampoints.com.br.
                  </span>
                </li>
                <li>
                  <span>
                    A finalidade deste Programa é gerar relacionamento e
                    engajamento dos Vendedores Lojas com os produtos da MAM. Os
                    Vendedores das Lojas cadastrados serão pontuados através dos
                    produtos vendidos dentro do Estabelecimento cadastrado, de
                    acordo com as quantidades adquiridas da MAM.
                  </span>
                </li>
                <li>
                  <span>
                    Os produtos válidos para pontuação são os produtos vendidos
                    pela MAM, da marca MAM.
                  </span>
                </li>
                <li>
                  <span>
                    Os parceiros cadastrados serão denominados e identificados
                    como Membros do Programa MAM Points, sendo recompensados,
                    eventualmente, desde que sejam cumpridas as condições
                    descritas no presente regulamento.
                  </span>
                </li>
                <li>
                  <span>
                    A Smart executará o Programa MAM Points diretamente aos
                    Vendedores de Lojas comerciais indicados e cadastrados para
                    o Programa de Relacionamento pela MAM.
                  </span>
                </li>
                <li>
                  <span>
                    Caso a Loja do vendedor cadastrado estiver com inadimplência
                    com a MAM, os prêmios podem não ser entregues e o Ponto de
                    Venda pode ser excluído do Programa.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>REGRAS DE PONTUAÇÃO</span>
              <ol>
                <li>
                  <span>
                    O membro do Programa MAM Points, deverá enviar uma foto da
                    Nota Fiscal / Cupom Fiscal pelo site{' '}
                    <a target="_blank" href="/loja">
                      {process.env.REACT_APP_BASE_URL}
                    </a>{' '}
                    para que a pontuação seja realizada e confirmada.
                  </span>
                </li>
                <li>
                  <span>
                    A Foto de Nota Fiscal/Cupom Fiscal deve ser enviada de forma
                    nítida, legível e inequívoca a quantidade e descrição dos
                    produtos, número da nota/cupom fiscal ou recibo e data da
                    compra. Para todos os efeitos, só serão aceitos comprovantes
                    válidos e legíveis oriundos de Lojas participantes do
                    Programa.
                  </span>
                </li>
                <li>
                  <span>
                    Em caso de pontuação automática, os pontos serão computados
                    de acordo com a periodicidade previamente acordada com a sua
                    revenda, podendo ser diária, semanal, quinzenal ou mensal.
                    Os pontos obtidos pelos participantes tem validade de 365
                    (trezentos e sessenta e cinco) dias, desde que a conta
                    permaneça ativa.
                  </span>
                </li>
                <li>
                  <span>
                    Os pontos obtidos pelos participantes tem validade de 365
                    (trezentos e sessenta e cinco) dias, desde que a conta
                    permaneça ativa.
                  </span>
                </li>
                <li>
                  <span>
                    Da mesma forma, caso o participante não atinja quantidade
                    suficiente de pontos para troca de prêmios
                    <a target="_blank" href="/loja">
                      nesse link
                    </a>
                    , os pontos serão expirados, decorridos os prazos acima
                    indicados.
                  </span>
                </li>
                <li>
                  <span>
                    O extrato de pontos poderá ser consultado{' '}
                    <a target="_blank" href="/">
                      nesse link
                    </a>
                    , no menu PONTUAÇÕES, juntamente com login (login: número do
                    CPF) e senha.
                  </span>
                </li>
                <li>
                  <span>
                    As pontuações são validadas diretamente com o volume de
                    compras realizadas pelo estabelecimento com a MAM.
                  </span>
                </li>
                <li>
                  <span>Os pontos são pessoais e intransferíveis.</span>
                </li>
                <li>
                  <span>
                    A Smart se reserva no direito da revisão dos pontos até 90
                    (noventa) dias após a data de pontuação, para conferência e
                    correção se necessário.
                  </span>
                </li>
                <li>
                  <span>
                    Os pontos obtidos no decorrer deste Programa poderão ser
                    utilizados de uma única vez ou de forma fracionada.
                  </span>
                </li>
                <li>
                  <span>
                    Na eventualidade de algum membro participante ser desligado
                    do estabelecimento cadastrado, o saldo de pontos
                    permanecerão ativos, respeitando as regras de validade da
                    pontuação.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>REGRAS DE RESGATES DE PRÊMIOS</span>
              <ol>
                <li>
                  <span>
                    Está disponível no site do Programa MAM Points o CATÁLOGO
                    DE PRÊMIOS contendo os produtos, que poderão ser obtidos
                    pelos parceiros a partir da utilização do seu crédito de
                    PONTOS vigentes, nos termos deste REGULAMENTO e de outras
                    regras disponibilizadas no site.
                  </span>
                </li>
                <li>
                  <span>
                    O Programa MAM Points poderá alterar, substituir ou
                    cancelar os prêmios disponíveis.
                  </span>
                </li>
                <li>
                  <span>
                    Os resgates de prêmios deverão ser realizados através desse
                    link
                    <a target="_blank" href="/loja">
                      desse link
                    </a>
                    , mediante a login e senha e saldo suficiente para o item
                    escolhido.
                  </span>
                </li>
                <li>
                  <span>
                    Em caso de falha sistêmica ou em casos que o produto seja
                    resgatado fora da regra de pontuação vigente, os resgates
                    serão automaticamente cancelados.
                  </span>
                </li>
                <li>
                  <span>
                    Após o resgate solicitado no site descrito acima, o membro
                    do Programa MAM Points receberá um e-mail e a equipe de
                    apoio ao vendedor loja entrará em contato através do
                    telefone de cadastro se necessário.
                  </span>
                </li>
                <li>
                  <span>
                    O prêmio resgatado será entregue para o vendedor loja no
                    estabelecimento cadastrado ou no endereço cadastrado do
                    membro do Programa em até 20 dias úteis, sendo a entrega
                    feita mediante Formulário de Resgate, válido como recibo
                    assinado, ou confirmação da transportadora.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>POLÍTICA DE PROTEÇÃO DE DADOS PESSOAIS</span>
              <ol>
                <li>
                  <span>
                    Os dados pessoais serão coletados pela Smart com a
                    finalidade de administração e operação do PROGRAMA,
                    incluindo o uso para os fins de: (i) cadastro junto ao
                    PROGRAMA e contato com o Membro do Programa MAM Points;
                    (ii) acúmulo de PONTOS; (iii) entrega de premiações; (iv)
                    otimização do catálogo de prêmios; (v) desenvolvimento de
                    inteligência de mercado, parâmetros e perfil de consumo, bem
                    como outras atividades associadas ao bom funcionamento e
                    desenvolvimento do PROGRAMA.
                  </span>
                </li>
                <li>
                  <span>
                    Os dados serão armazenados em bancos de dados exclusivos do
                    Programa, em ambiente seguro.
                  </span>
                </li>
                <li>
                  <span>
                    A SMART não compartilhará os dados pessoais aqui mencionados,
                    exceto com a própria BEBE SAUDE LTDA.
                  </span>
                </li>
                <li>
                  <span>
                    O MAM garante que os dados recebidos serão utilizados para
                    análise de mercado e encaminhamento de publicidade sobre
                    produtos da marca MAM.
                  </span>
                </li>
                <li>
                  <span>
                    A MAM garante que os dados recebidos serão armazenados em
                    ambiente seguro e com acesso restrito somente a empregados
                    da empresa, todos contratualmente obrigados a mantê-los em
                    proteção e sob sigilo.
                  </span>
                </li>
                <li>
                  <span>
                    A MAM garante também que os dados serão compartilhados com
                    empresas provedoras do serviço de armazenamento e gestão dos
                    dados, todas contratualmente obrigadas a mantê-los em
                    segurança e sob sigilo.
                  </span>
                </li>
                <li>
                  <span>
                    Quando muito necessário, a MAM poderá compartilhar os dados
                    recebidos com prestadores de serviço, para aprimorar o
                    atendimento da empresa aos seus clientes, bem como com
                    consultores jurídicos, para ingresso ou defesa em ações
                    administrativas ou judiciais. Todos contratualmente
                    obrigados a manter a confidencialidade das informações.
                    Poderá compartilhar também para o cumprimento de
                    determinação de autoridade judicial ou administrativa
                    competente.O Membro do Programa MAM Points poderá a
                    qualquer momento corrigir dados incompletos, inexatos ou
                    desatualizados informados em cadastro, por meio do menu
                    “MINHA CONTA” após o Login.
                  </span>
                </li>
                <li>
                  <span>
                    A qualquer momento, o membro do Programa MAM Points pode
                    solicitar a exclusão total de seus dados.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>CONSIDERAÇÕES GERAIS</span>
              <ol>
                <li>
                  <span>
                    No primeiro acesso, o Membro do Programa MAM Points deve
                    efetivar o seu ACEITE a este REGULAMENTO, formalizando,
                    portanto, a sua decisão de participar do PROGRAMA,
                    declarando ter amplo conhecimento e concordar integralmente,
                    de maneira plena e irrestrita, com todos os termos e
                    condições do MAM, estando apto a usufruir dos benefícios do
                    PROGRAMA que lhe forem aplicáveis. Caso o ACEITE não seja
                    efetivado, o acesso do Programa MAM Points estará inapto
                    para uso.
                  </span>
                </li>
                <li>
                  <span>
                    A participação neste Programa implica na aceitação total de
                    todos os itens deste Regulamento, que poderá ser alterado
                    pela Smart tantas vezes quanto necessário, a seu exclusivo
                    critério.
                  </span>
                </li>
                <li>
                  <span>
                    No decorrer deste Programa, a Smart irá se comunicar com o
                    público-alvo, de forma próxima e direta, utilizando-se dos
                    principais canais de comunicação.
                  </span>
                </li>
                <li>
                  <span>
                    O Programa, por exclusivo critério da Smart, poderá ser
                    interrompida ou encerrada a qualquer tempo.
                  </span>
                </li>
                <li>
                  <span>
                    Serão automaticamente desclassificados os participantes que
                    descumprirem os itens do presente regulamento, agirem de
                    má-fé ou que, de alguma forma, burlarem as regras e
                    condições deste Regulamento ou utilizarem mecanismos
                    fraudulentos ou ilícitos.
                  </span>
                </li>
                <li>
                  <span>
                    Ao participar deste Programa, o vendedor da loja concorda,
                    desde já, com a utilização de seu nome e imagem, para
                    divulgação desta ação, em todos os meios de comunicação,
                    pelo prazo máximo de 3 (três) anos contado a partir da data
                    de cadastro, sem que isso traga qualquer tipo de ônus para a
                    empresa a Smart ou Promotora.
                  </span>
                </li>
                <li>
                  <span>
                    A participação no Programa MAM Points é voluntária,
                    facultativa e gratuita, não havendo qualquer sanção àqueles
                    que optem por não participar; e as dúvidas relativas a este
                    Programa poderão ser solucionadas de segunda-feira a
                    sexta-feira (exceto feriados) das 9hs às 18shs, através dos
                    canais de comunicação:
                    <br />
                    <br />
                    Canal Fale Conosco no site do Programa MAM Points (após
                    Login):
                    <br />
                    WhatsApp: (11) 5199-0494
                    <br />
                    Telefone: (11) 5199-0494
                    <br />
                    E-mail: contato@mampoints.com.br
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>UTILIZAÇÃO DO CARTÃO</span>
              <ol>
                <li>
                  <span>
                    No Primeiro resgate em dinheiro, você receberá seu cartão
                    Programa MAM Points. Os próximos resgates em dinheiro,
                    serão creditados automaticamente neste mesmo cartão. Cada
                    Vendedor terá apenas 1 (um) cartão, é valido somente 1 por
                    CPF.
                  </span>
                </li>
                <li>
                  <span>
                    Utilize sempre seu CARTÃO MASTERCARD® Programa MAM Points
                    na função CRÉDITO.
                  </span>
                </li>
                <li>
                  <span>
                    Com o cartão em mãos, você pode fazer o desbloqueio{' '}
                    <a target="_blank" href="/cartao">
                      nesse link
                    </a>{' '}
                    ou pelo telefone (11) 5199-0494 de segunda a sexta das 9h às
                    18h.
                  </span>
                </li>
                <li>
                  <span>
                    Para DESBLOQUEIO pelo site, o usuário precisa entrar no
                    sistema com seu CPF e senha.
                  </span>
                </li>
                <li>
                  <span>
                    O prazo da operadora para DESBLOQUEIO do seu cartão é de 24h
                    úteis após a solicitação.
                  </span>
                </li>
                <li>
                  <span>
                    Em caso de perda ou roubo do cartão, solicite o BLOQUEIO{' '}
                    <a target="_blank" href="/cartao">
                      nesse link
                    </a>{' '}
                    ou pelo telefone (11) 5199-0494 de segunda a sexta das 9h às
                    18h.
                  </span>
                </li>
                <li>
                  <span>
                    O prazo da operadora para BLOQUEIO do seu cartão é de 24h
                    úteis após a solicitação.
                  </span>
                </li>
                <li>
                  <span>
                    Para alterar a senha, o usuário será direcionado para o site
                    da operadora do cartão onde por segurança, será necessário
                    completar os dados pessoais e fazer um cadastro ao painel de
                    controle.
                  </span>
                </li>
                <li>
                  <span>
                    O Programa Programa MAM Points, não cobra taxas ou pontos
                    para as operações feitas dentro do painel do usuário no site
                    da operadora. As taxas são cobradas pela operadora do cartão
                    e constam neste regulamento.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>SERVIÇOS E TAXAS DA OPERADORA DO CARTÃO </span>
              <ol>
                <li>
                  <span>
                    O portador do cartão pode utilizar os seguintes serviços no
                    painel da operadora:
                    <br />
                    TRANSFERÊNCIA BANCÁRIA.
                    <br />
                    RECARGA DE CELULAR.
                    <br />
                    PAGAMENTO DE CONTA.
                    <br />
                    CONSULTA DE SALDO E EXTRADO DO CARTÃO.
                    <br />
                    ALTERAÇÃO DE SENHA.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>TRANSFERÊNCIA BANCÁRIA</span>
              <ol>
                <li>
                  <span>O Usuário deverá cadastrar uma conta em seu nome.</span>
                </li>
                <li>
                  <span>
                    Não é permitido transferência para conta de terceiros.
                  </span>
                </li>
                <li>
                  <span>
                    Somente após a confirmação do código SMS e validação do
                    saldo do seu cartão é que a transferência será agendada.
                  </span>
                </li>
                <li>
                  <span>
                    Solicitações efetuadas ATÉ as 15:00 horas são enviadas ao
                    banco no mesmo dia.
                  </span>
                </li>
                <li>
                  <span>
                    Solicitações efetuadas APÓS as 15:00 horas são enviadas ao
                    banco no próximo dia útil. TED - Será postado na conta
                    corrente no mesmo dia. DOC para conta poupança - Será
                    postado na conta no dia seguinte.
                  </span>
                </li>
                <li>
                  <span>Tarifa para Transferência: R$ 5,50.</span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>RECARGA DE CELULAR</span>
              <ol>
                <li>
                  <span>
                    O premiado pode cadastrar o telefone dele ou de outras
                    pessoas para uso em suas recargas. Mas somente telefones
                    cadastrados e validados por um código SMS poderão ser
                    recarregados.
                  </span>
                </li>
                <li>
                  <span>
                    Somente após a confirmação do código SMS e validação do
                    saldo do seu cartão é que a recarregar será feita em no
                    telefone pré-cadastrado.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>PAGAMENTO DE CONTA</span>
              <ol>
                <li>
                  <span>
                    Somente após a confirmação do código SMS e validação do
                    saldo do seu cartão é que o pagamento será feito. Um
                    comprovante será gerado validando a sua transação.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>
                CONSULTA DE SALDO E EXTRATO DO CARTÃO E ALTERAÇÃO DE SENHA
              </span>
              <ol>
                <li>
                  <span>
                    No painel online da operadora de cartão, não haverá custo.
                  </span>
                </li>
                <li>
                  <span>
                    Para consulta de saldo online ou alteração de senha, o
                    premiado deverá estar com o cadastro completo no painel da
                    operadora do cartão.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>BANCO 24h</span>
              <ol>
                <li>
                  <span>
                    O Premiado pode consultar o saldo nos bancos 24h mediante
                    taxa de R$ 2,24.
                  </span>
                </li>
                <li>
                  <span>
                    O Premiado pode efetuar saques na rede Banco 24h mediante
                    tarifa de R$ 9,00.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>TAXA DE INATIVIDADE</span>
              <ol>
                <li>
                  <span>
                    O MAM, deverá enviar uma foto da Nota Fiscal / Cupom Fiscal
                    <a href="/"> nesse </a> para que a pontuação seja realizada
                    e confirmada.
                  </span>
                </li>
                <li>
                  <span>
                    Em caso do cartão estiver sem saldo, não será cobrado a taxa
                    de inatividade.
                  </span>
                </li>
                <li>
                  <span>
                    Em caso de perda do cartão, você poderá receber um novo
                    cartão sem custo, mas para transferir o saldo para um novo
                    cartão, será cobrado pela operadora do cartão uma taxa de 2%
                    do seu saldo atual.
                  </span>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <span>MASTERCARD® SURPREENDA</span>
              <ol>
                <li>
                  <span>
                    O Usuário pode se cadastrar no programa MASTERCARD®
                    SURPREENDA, que é um programa de vantagens administrado pela
                    MASTERCARD®.
                  </span>
                </li>
                <li>
                  <span>
                    Todo o acesso e controle do programa MASTERCARD® SURPREENDA
                    é separado e independente do Programa MAM Points.
                  </span>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </Container>
  );
}
