import React, { useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import './styles.css';
import { Form, Input } from '@rocketseat/unform';
import { useDispatch, useSelector } from 'react-redux';
import { autenticarParceiro } from '../../../store/modules/autenticacao/actions';
import Footer from '../../../components/Loja/Footer';
import Header from '../../../components/Loja/Header';
import { Link } from 'react-router-dom';

export default function Login() {
  const dispatch = useDispatch();
  const enviando = useSelector((state) => state.autenticacao.autenticandoLoja);

  useEffect(() => {
    document.title = 'MAM - Login';
  }, []);

  const solicitarAutenticacao = ({ cpf, senha }) => {
    dispatch(autenticarParceiro(cpf, senha));
  };

  return (
    <div className="w-100 bg-white">
      <Container fluid className="d-flex flex-column justify-content-center">
        <Row className="flex-column align-items-center justify-content-center">
          <img
            className="w-100"
            src="/imagens/participe-banner-home.jpg"
            alt="Logo Malwee"
          ></img>
        </Row>
        <Row className="justify-content-center mt-5 mb-5">
          <Form
            onSubmit={solicitarAutenticacao}
            className="form-login form-login-loja"
          >
            <h5>FAÇA SEU LOGIN</h5>
            <label>Entre com seus dados e acesse sua conta</label>
            <label htmlFor="cpf">CPF</label>
            <Input name="cpf" type="text" placeholder="CPF"></Input>
            <label htmlFor="senha">Senha</label>
            <Input name="senha" type="password" placeholder="Senha"></Input>
            {enviando ? (
              <button disabled className="btn-primario" type="submit">
                VALIDANDO...
              </button>
            ) : (
              <button className="btn-primario" type="submit">
                ENTRAR
              </button>
            )}
            <Link
              className="mb-3 mt-3 text-center link-clean"
              to="/esqueceu-senha"
            >
              Esqueceu sua senha?
            </Link>
          </Form>
        </Row>
      </Container>
      <Footer />
    </div>
  );
}
